<script>
/**
 * Widget Component
 */

import { reportService } from '../../../services/report.service';

export default {
  data() {
    return {
      servicesData: {},
      dashboardReportId: "",
      userRole: JSON.parse(localStorage.getItem('user')).role,
      shopId: JSON.parse(localStorage.getItem('user')).shopId,
    };
  },

  created() {
    if (this.userRole === 'user' || this.userRole === 'shop-admin') {
      this.dashboardReportId = this.shopId;
    } else {
      this.dashboardReportId = "";
    }

    this.getDashboardReport()
  },

  methods: {
    async getDashboardReport() {
      try {
        await reportService.getGlobalServicesDashboard(this.dashboardReportId).then(response => {
          if (response.status === "SUCCESS") {
            this.servicesData = response.body;
            console.log(response.body);
          }
        });
      } catch (error) {
        console.log(error);
      }
    },

  }

};
</script>

<template>
  <div class="row ">
    <div class="col-md-4" v-for="(data, index) in servicesData" :key="index">
      <h2 class="m-3">{{ data.title }}</h2>

      <div class="card mt-4">

        <div v-for="(serviceProvider, serviceIndex) in data.options" :key="serviceIndex">

          <div class="card-body mt-2">
           
            <div class="media ">
              <div class="media-body overflow-hidden">
                <img :alt="serviceProvider.title" :src="serviceProvider.image" width="150" max-height="10" />
                <!-- <h5 class="mb-0">{{serviceProvider.title}}</h5> -->
              </div>
              <div class="text-primary">
                <i :class="`ri-apps-2-fill font-size-24`"></i>
              </div>
            </div>
            <div class="card-body border-top py-3 mt-3">
              <p>
                <span v-for="(servicePermission, servicePermissionIndex) in serviceProvider.options" :key="servicePermissionIndex">
                  {{servicePermission.title}},
                </span>
              </p>
              <!-- <img :alt="serviceProvider.title" :src="serviceProvider.image" width="150" max-height="10" /> -->

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end row -->
</template>