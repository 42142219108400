<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import router from '../../../router/index'

import Services from "./ServicesWidget";  


/**
 * Dashboard component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Services, 
  },
  data() {
    return {
      title: "Services",
      items: [
        {
          text: "All Services"
        },
        {
          text: "Dashboard",
          active: true
        }
      ]
    };
  },

  created() { 
    //JSON.parse(localStorage.getItem('accessRights')).some(access => access.groupName === 'Teller Dashboard')
    if(JSON.parse(localStorage.getItem('accessRights')).some(access => access.groupName === 'Teller Dashboard')){
      router.push('/teller-dashboard')
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="">
      <div class="col-xl-12">
        <Services /> 
      </div> 
    </div>
  </Layout>
</template>